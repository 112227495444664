/**
 * Class will be added if window scroll position more than FADE_OFFSET.
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');

/**
 * Maximum mobile window width px.
 */
var MAX_MOBILE_WIDTH = 767;

/**
 * Scroll debounce delay in ms.
 */
var DEBOUNCE_DELAY = 100;

var hasClass = false;
var _ = require('underscore');

module.exports = {
  init: function(element, valueAccessor) {
    var $el = $(element);
    var data = ko.unwrap(valueAccessor());
    var $window = $(window);
    var classToAdd = data.classToAdd;
    var addClassfromTopOfElement = data.fromTop;
    var descktopVersion = data.descktopVersion;
    var addClassFromBottomOfPage = data.addClassFromBottomOfPage;
    var debounceDelay = data.debounceDelay || DEBOUNCE_DELAY;
    var $addClassFromBlock = $(data.offsetElementSelector);
    var elementHeight;
    var blockOffset;
    var scrollOffset;

    if ((!_.isUndefined(data.show) && !data.show) || !$addClassFromBlock.length) {
      $el.hide();
      return;
    }

    if (!$addClassFromBlock.length) {
      console.log('Error: block does not exist');
      return;
    }

    function checkPosition() {
      var screenHeight = $window.height();
      var scrollTop = $window.scrollTop();

      blockOffset = parseInt($addClassFromBlock.offset().top, 10);

      if (!addClassfromTopOfElement) {
        elementHeight = parseInt($addClassFromBlock.outerHeight(), 10);
        blockOffset += elementHeight;
      }


      if (addClassFromBottomOfPage) {
        scrollOffset = scrollTop + screenHeight;
      } else {
        scrollOffset = scrollTop;
      }

      if ((descktopVersion &&  $window.width() > MAX_MOBILE_WIDTH) || !descktopVersion) {
        if (scrollOffset >= blockOffset) {
          $el.addClass(classToAdd);
          hasClass = true;
        } else {
          $el.removeClass(classToAdd);
          hasClass = false;
        }

        if (!_.isUndefined(data.hasClass)) {
          data.hasClass(hasClass);
        }
      }
    }

    $(window).scroll(_.debounce(checkPosition, debounceDelay));
    checkPosition();
  }
};
