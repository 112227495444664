/**
 * Binding for jquery ui tooltip
 *
 * Usage:
 *    <div title="Tooltip content" data-bind="tooltip">Visible content</div>
 *  OR
 *    <div title data-bind="tooltip: {title: 'Tooltip content'}">Visible content</div>
 *  OR
 *    <div data-bind="tooltip" title>
 *      Visible content
 *      <div class="js-tooltip-container hidden">
 *        Tooltip content
 *      </div>
 *    </div>
 * Additionally, you can pass `autoShow` and `autoHide` parameters and
 *  set `value` to boolean observable, to take control over visibility
 *  behavior.
 */

 'use strict';
var ko = require('knockout');
var $ = require('jquery');
var _ = require('underscore');
require('plugins/tooltipX');
var getSettings = require('./config');

var TOOLTIP_CONTENT_CLASS_NAME = '.js-tooltip-container';

module.exports = {
  init: function(element, valueAccessor, allBindingsAccessor, context, bindingContext) {
    var $el = $(element);
    var value = ko.unwrap(valueAccessor());
    var title = $el.attr('title');
    var tooltipContent = $el.find(TOOLTIP_CONTENT_CLASS_NAME);
    var withContent = tooltipContent.length > 0;
    var settings = getSettings(withContent ? 'content' : 'title', value);

    var getContent = function() {
      var paramText = value ? value.title : false;

      if (paramText) {
        return paramText;
      } else if (withContent) {
        // Take actual content always
        var elem = $el.find(TOOLTIP_CONTENT_CLASS_NAME).html();

        // return bindingContext to apply bindings
        return [elem, bindingContext];
      } else if (title) {
        return title;
      } else {
        console.debug('Tooltip text didnt specified.');
      }
    };

    settings.content = getContent;

    $el.tooltipX(settings);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $el.tooltipX('destroy');
    });
  },

  update: function(element, valueAccessor) {
    var $el = $(element);
    var value = ko.unwrap(valueAccessor());
    var tooltipContent = $el.find(TOOLTIP_CONTENT_CLASS_NAME);
    var withContent = tooltipContent.length > 0;
    var settings = getSettings(withContent ? 'content' : 'title', value);
    var visible = ko.unwrap(settings.visible);
    var disable = ko.unwrap(settings.disable);
    var show = value ? value.show : true;

    if (disable) {
      $el.tooltipX('disable');
      return;
    }

    if (visible) {
      $el.tooltipX('open');
    } else if (!visible || !show) {
      $el.tooltipX('close');
    }
  }
};
