/**
 * Require ajaxForms in right way
 */

var forms = require(
  'imports-loader?' +
    'jQuery=jquery,' +
    '_=underscore,' +
    'Porthole=../../../plugins/porthole,' +
    'URI=URIjs,' +
    'window=../../../plugins/ajaxforms/fakeWindow.js!' +
  'exports-loader?HtmlForms=window.HtmlForms,AjaxForms=window.AjaxForms!' +
  '../../externals/jsbase/js/scripts.js'
);

module.exports = forms;
