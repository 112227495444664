var ko = require('knockout')
var $ = require('jquery')

var Vue = window.VuePSS ? window.VuePSS.vue : null
var componentInstances = new Set()


// since vue instances are not killed when element leaves dom
// we should store them and control their actuality
function killObsoleteInstances() {
  componentInstances.forEach(function (component) {
    var notMountedAndInstantiated = !$.contains(document.documentElement, component.$el) && component.$el.__vue__

    if (notMountedAndInstantiated) {
      componentInstances.delete(component)
      component.$destroy()
    }
  })
}

setInterval(killObsoleteInstances, 500)

function invokeComponent (component) {
  return Promise.resolve(
    component.prototype instanceof Vue
      ? component
      : component().then(function (component) {return component.default})
  )
}

/*
  Knockout-to-Vue bridge to render Vue components inside Knockout templates.

  Usage example:
  <div data-bind="vueComponent: {
      component: 'button-base',
      props: {
          text: '{{ _('Купить') }}',
          modificators: $root.buildPurchaseButtonModificators(item),
          onClick: $root.purchaseButtonClick.bind($root, item),
          disabled: false
      }
  }"></div>
*/

module.exports = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    // This will be called when the binding is first applied to an element
    // Set up any initial state, event handlers, etc. here
    return {
      controlsDescendantBindings: true
    }
  },
  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    // This will be called once when the binding is first applied to an element,
    // and again whenever any observables/computeds that are accessed change
    // Update the DOM element based on the supplied values here.
    var options = ko.unwrap(valueAccessor())

    if (options && options.component && window.VuePSS) {
      var componentModule = Vue.component(options.component)

      // exclude not registered global components
      if (componentModule) {
        // await if component used through dynamic import
        invokeComponent(componentModule).then(function (ComponentClass) {
          var mountOptions = {
            propsData: options.props
          }

          if (options.injectRouter && window.VuePSS.router ){
            mountOptions.router = window.VuePSS.router
          }

          if (window.VuePSS.store) {
            mountOptions.store = window.VuePSS.store
          }

          var componentInstance = new ComponentClass(mountOptions)

          componentInstance.$mount(element)
          componentInstances.add(componentInstance)
        })
      } else {
        console.error(options.component + ' not registered')
      }
    }
  }
}
