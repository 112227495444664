var ko = require('knockout');
var $ = require('jquery');

/**
 * Provide 2-way data binding for select
 */
module.exports = function(element, value, settings) {
  var $el = $(element);
  var box = $el.wgCombobox().data('plugin_wgCombobox');
  var placeholder = ko.unwrap(settings.placeholder);

  // If select value changed.
  if (ko.isWritableObservable(value)) {
    $el.on('selectitem', function() {
      var selectedValue = box.getSelectedValue();
      if (selectedValue !== placeholder) {
        value(selectedValue);
      } else {
        value('');
      }

      box.hideDropdown();
    });
  }

  // Select item in select element when model updates
  var subscription = ko.computed(function() {
    // Subscribe
    var rawValue = ko.unwrap(value);
    if (rawValue.length === 0) {
      rawValue = placeholder;
    }

    box.setSelectedItemByValue(rawValue);
  });

  // Dispose event handlers when element was dismounted
  var dispose = function() {
    $el.off('selectitem');
    subscription.dispose();
  };

  return dispose;
};
