/**
 * Init knockout components
 */

'use strict';

var ko = require('knockout');

var components = [
  'button/base',
  'overlay/waiting'
];

for (var i = 0; i < components.length; i++) {
  var component = components[i];

  // replace `/` and `_` with `-`
  var componentName = component.replace(/[\/_]/gi, '-');
  ko.components.register(componentName, require('./' + component + '/index.js'));
}
