/**
 * Module with generic scripts.
 * Should be used on pages with no need in common menu.
 * For pages with common menu use _page_commons.js instead.
 */

'use strict';

// jscs:disable requireCamelCaseOrUpperCaseIdentifiers

// Set static url for Webpack runtime
__webpack_public_path__ = window.Settings.STATIC_URL;

// jscs:enable requireCamelCaseOrUpperCaseIdentifiers

//es5 shim
require('es5-shim');

// Require once to add to knockout reference to jquery
require('plugins/knockout');

require('bindings');

// Register knockout components
require('components');

require('extenders');

// Init jquery ui
require('plugins/jquery-ui.min.js');

// Init jquery.cookie
require('jquery.cookie');

// Require wg internal openid
require('plugins/openid');

require('knockout-mapping');
require('knockout-postbox');