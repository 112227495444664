'use strict';

var ko = require('knockout');

var extenders = [
  'new_value_validator',
  'uppercase'
];

function camelCase(input) {
  return input.toLowerCase().replace(/_(.)/g, function(match, group1) {
    return group1.toUpperCase();
  });
}

// The names of all bindings are converted into camel case style
for (var i = 0; i < extenders.length; i++) {
  var extenderName = extenders[i];

  ko.extenders[camelCase(extenderName)] = require('./' + extenderName + '/index.js');
}
