/**
 * Knockout component for animation in waiting mode
 */
 'use strict';

var ko = require('knockout');
var $ = require('jquery');
var _ = require('underscore');
var Spinner = require('plugins/spinner/spin');

var SPINNER_CLASS_NAME = 'spinner';
var SPNNER_CONTAINER_SELECTOR = '.js-spinner-container';

var DEFAULT_BUTTON_SPINNER_OPTIONS = {
  lines: 12, // The number of lines to draw
  length: 4, // The length of each line
  width: 2, // The line thickness
  radius: 4, // The radius of the inner circle
  corners: 1, // Corner roundness (0..1)
  rotate: 0, // The rotation offset
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: '#ffe4d3', // #rgb or #rrggbb or array of colors
  speed: 1, // Rounds per second
  trail: 60, // Afterglow percentage
  shadow: false, // Whether to render a shadow
  hwaccel: false, // Whether to use hardware acceleration
  className: SPINNER_CLASS_NAME, // The CSS class to assign to the spinner
  zIndex: 2e9, // The z-index (defaults to 2000000000)
  top: '50%', // Top position relative to parent
  left: '50%' // Left position relative to parent
};

module.exports = {
  update: function(element, valueAccessor) {
    var $el = $(element);
    var value = ko.unwrap(valueAccessor());
    var visible = ko.unwrap(value.visible) || false;
    var waitingClass = value.waitingClass || '';
    var overrides = value.options || {};
    var $spinnerContainer = $(SPNNER_CONTAINER_SELECTOR, $el);
    var settings = _.extend({}, DEFAULT_BUTTON_SPINNER_OPTIONS, overrides);

    if (!$spinnerContainer.length) {
      $spinnerContainer = $el;
    }

    if (visible) {
      $spinnerContainer.addClass(waitingClass);
      (new Spinner(settings)).spin($spinnerContainer.get(0));
    } else {
      $spinnerContainer.removeClass(waitingClass);
      $('.' + SPINNER_CLASS_NAME, $spinnerContainer).remove();
    }
  }
};
