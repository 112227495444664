'use strict';

var ko = require('knockout');

module.exports = function(target, validator) {
  return ko.computed({
    read: target,
    write: function(newValue) {
      var currentValue = target();

      if (validator(currentValue, newValue)) {
        target(newValue);
      } else {
        target.notifySubscribers(currentValue);
      }
    }
  }).extend({notify: 'always'});
};
