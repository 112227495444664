/**
 * Knockout component for abstract button
 */
 'use strict';

var ko = require('knockout');
var $ = require('jquery');

var Spinner = require('plugins/spinner/spin.js');

var SPINNER_CLASS_NAME = 'spinner';
var ANIMATION_DURATION = 200;
var SHOP_BUTTON_SPINNER_OPTIONS = {
  lines: 12, // The number of lines to draw
  length: 4, // The length of each line
  width: 2, // The line thickness
  radius: 4, // The radius of the inner circle
  corners: 1, // Corner roundness (0..1)
  rotate: 0, // The rotation offset
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: '#ffe4d3', // #rgb or #rrggbb or array of colors
  speed: 1, // Rounds per second
  trail: 60, // Afterglow percentage
  shadow: false, // Whether to render a shadow
  hwaccel: false, // Whether to use hardware acceleration
  className: SPINNER_CLASS_NAME, // The CSS class to assign to the spinner
  zIndex: 10000, // The z-index (defaults to 2000000000)
  top: '50%', // Top position relative to parent
  left: '50%' // Left position relative to parent
};

var BaseButtonViewModel = function(params) {
  var _this = this;
  this.modificators = {};
  var _modificators = params.modificators || [];
  for (var i = 0; i < _modificators.length; i++) {
    var modificator = _modificators[i];
    this.modificators[modificator] = true;
  }

  this.visible = params.visible || ko.observable(true);
  this.isSpinnerDeactivated = params.isSpinnerDeactivated;
  this.label = params.text;
  this.hint = params.title || this.label;
  this.id = params.id;
  this.iconClass = params.icon;
  this.iconCss = {};
  if (this.iconClass) {
    this.iconCss[this.iconClass] = true;
  }

  this.onClickedCallback = params.onClick;
  this.onRenderedCallback = params.onRendered;
  this.onMouseOverCallback = params.onMouseOver;
  this.onMouseOutCallback = params.onMouseOut;
  this.waiting = params.waiting === undefined ? false : params.waiting;
  this.defaultDisabled = params.defaultDisabled === undefined ? false : ko.unwrap(params.defaultDisabled);

  this.disabled = ko.pureComputed(function() {
    return params.disabled === undefined ? _this.defaultDisabled : ko.unwrap(params.disabled);
  });

  this.modificators.button__disabled = ko.pureComputed(function() {
    return _this.disabled();
  });
  this.link = params.link;
  this.small = params.small;

  if (!params.modificators) {
    this.modificators['button'] = true;
  }

  if (this.small) {
    this.modificators['button__inline'] = true;
  }
};

BaseButtonViewModel.prototype.onButtonClicked = function(viewModel, event) {
  if (ko.unwrap(this.waiting) || ko.unwrap(this.disabled)) {
    return;
  }

  if (this.onClickedCallback) {
    this.onClickedCallback(event);
  } else {
    return Boolean(this.link);
  }
};

BaseButtonViewModel.prototype.componentRendered = function() {
  if (this.onRenderedCallback) {
    this.onRenderedCallback();
  }
};

BaseButtonViewModel.prototype.onMouseOver = function() {
  if (this.onMouseOverCallback) {
    this.onMouseOverCallback();
  }
};

BaseButtonViewModel.prototype.onMouseOut = function() {
  if (this.onMouseOutCallback) {
    this.onMouseOutCallback();
  }
};


module.exports = {
  viewModel: BaseButtonViewModel,
  template: {element: 'base-button-component-template'}
};


/** Shop button transitions **/

var makeWaiting = function(element, isSpinnerDeactivated) {
  var $element = $(element);
  var isSmall = $element.hasClass('small-button');
  $element.addClass(isSmall ? 'small-button__wait' : 'button__wait');

  if (!isSpinnerDeactivated) {
    new Spinner(SHOP_BUTTON_SPINNER_OPTIONS).spin(element);
  }

  $('.js-shop-button-icon', $element).fadeOut(ANIMATION_DURATION);
};

var makeNormal = function(element, isSpinnerDeactivated) {
  var $element = $(element);
  $('.js-shop-button-icon', $element).fadeIn(ANIMATION_DURATION);

  if (!isSpinnerDeactivated) {
    $('.' + SPINNER_CLASS_NAME, $element).remove();
  }

  var isSmall = $element.hasClass('small-button');
  $element.removeClass(isSmall ? 'small-button__wait' : 'button__wait');
};

ko.bindingHandlers.buttonWaiting = {
  update: function(element, valueAccessor) {
    var value = valueAccessor();

    if (ko.unwrap(value.waiting)) {
      makeWaiting(element, value.isSpinnerDeactivated);
    } else {
      makeNormal(element, value.isSpinnerDeactivated);
    }
  }
};
