/**
 * This module provides functions for localization
 */
'use strict';

var settings = require('settings');
var LANGUAGE_CURRENCY_FORMATS = settings.LANGUAGE_CURRENCY_FORMATS,
    CURRENCY_SIGNS = settings.CURRENCY_SIGNS;

var truncateZeroDecimalNumbers = function(price, decSeparator) {
    if (typeof price == 'undefined'){
        return price;
    }
    var re = new RegExp("\\" + decSeparator + "0+$", "g")
    return price.replace(re, "");
};

var Localization = {
  // TODO: refactor both methods to use Locale

  getLocalizedPrice: function(value, lang) {
    // format price value based on locale
    var numberParts = value.toString().split('.'),
      intPart = null,
      decPart = null,
      decSeparator = LANGUAGE_CURRENCY_FORMATS[lang][0],
      thousandSeparator = LANGUAGE_CURRENCY_FORMATS[lang][1],
      grouping = LANGUAGE_CURRENCY_FORMATS[lang][2],
      separateFourDigit = LANGUAGE_CURRENCY_FORMATS[lang][3],
      intGrouped = '',
      result = '';
    if (numberParts.length == 2) {
      intPart = numberParts[0];
      decPart = numberParts[1];
    } else {
      intPart = numberParts[0];
    }

    if (grouping > 0) {
      for (var i = intPart.length - 1, j = 1; i >= 0; i--, j++) {
        if (i && !(j % grouping) && (separateFourDigit || intPart.length != 4)) {
          intGrouped = thousandSeparator + intPart.charAt(i) + intGrouped;
          j = 0;
        } else {
          intGrouped = intPart.charAt(i) + intGrouped;
        }
      }
    } else {
      intGrouped = intPart;
    }

    result += intGrouped;
    if (decPart) {
      result += decSeparator + decPart;
    }

    return truncateZeroDecimalNumbers(result, decSeparator);
  },

  getLocalizedGold: function(value, lang) {
    // format gold value based on locale
    var thousandSeparator = LANGUAGE_CURRENCY_FORMATS[lang][1],
        grouping = LANGUAGE_CURRENCY_FORMATS[lang][2],
        intGrouped = '',
        result = '';

    value = value + '';
    if (grouping > 0) {
      for (var i = value.length - 1, j = 1; i >= 0; i--, j++) {
        if (i && !(j % grouping)) {
          intGrouped = thousandSeparator + value.charAt(i) + intGrouped;
          j = 0;
        } else {
          intGrouped = value.charAt(i) + intGrouped;
        }
      }
    } else {
      intGrouped = value;
    }

    result += intGrouped;
    return result;
  },

  formatPrice: function(price, country, currency, addTitle) {
    // format price with currency symbol based on Country
    var currencySign = CURRENCY_SIGNS[currency];
    var countryFormat = settings.COUNTRY_FORMAT;
    var args = null;
    var decoratedPrice = '<span class="js-price">' + price+ '</span>';

    addTitle = addTitle !== undefined ? addTitle : true;

    if (currencySign && addTitle) {
      currencySign = '<span class="currency-' + currency.toLowerCase() + '" title="' + currency + '">' +
      currencySign + '</span>';
    } else {
      currencySign = ' ' + currency;
    }

    if (countryFormat.at_start && addTitle) {
      args = [currencySign, countryFormat.separator, decoratedPrice];
    } else {
      args = [decoratedPrice, countryFormat.separator, currencySign];
    }

    return args.join('');
  }
};

module.exports = Localization;
