'use strict';

var ko = require('knockout');
var $ = require('jquery');
var _ = require('underscore');

/**
 * Scroll debounce delay in ms.
 */
var DEBOUNCE_DELAY = 100;

module.exports = {
  init: function(element, valueAccessor) {
    var $el = $(element);
    var data = ko.unwrap(valueAccessor());
    var $window = $(window);
    var hiddenMenuClass = data.hiddenMenuClass;
    var hiddenMenuAssemblyClass = data.hiddenMenuAssemblyClass;
    var shownMenuClass = data.shownMenuClass;
    var debounceDelay = data.debounceDelay || DEBOUNCE_DELAY;
    var $addClassFromBlock = $(data.offsetElementSelector);
    var blockHeight;
    var blockTopOffset;
    var blockBottomOffset;
    var scrollOffset;
    var lastScrollPisition = 0;
    var isWindowScrollingAnimation = false;

    if (!$addClassFromBlock.length) {
      console.log('Error: block does not exist');

      return;
    }

    ko.postbox.subscribe('isScrolling', function(isScrolling) {
      isWindowScrollingAnimation = isScrolling;
    });

    blockHeight = parseInt($addClassFromBlock.outerHeight(), 10);
    blockTopOffset = parseInt($addClassFromBlock.offset().top, 10);
    blockBottomOffset = blockTopOffset + blockHeight;

    function checkPosition() {
      if (isWindowScrollingAnimation) {
        return;
      }

      scrollOffset = $window.scrollTop();

      // fixing menu behind the screen
      if (scrollOffset >= blockBottomOffset && !$el.hasClass(shownMenuClass) && !$el.hasClass(hiddenMenuClass)) {
        $el.addClass(hiddenMenuAssemblyClass);
      } else if (scrollOffset <= blockTopOffset) {
        $el.removeClass(hiddenMenuClass).removeClass(hiddenMenuAssemblyClass).removeClass(shownMenuClass);
      }

      // when user scrolling top
      if (lastScrollPisition > scrollOffset && !$el.hasClass(shownMenuClass) &&
        ($el.hasClass(hiddenMenuClass) || $el.hasClass(hiddenMenuAssemblyClass))) {

        $el.addClass(shownMenuClass).removeClass(hiddenMenuAssemblyClass).removeClass(hiddenMenuClass);
      } else if (lastScrollPisition < scrollOffset && $el.hasClass(shownMenuClass) &&
        (!$el.hasClass(hiddenMenuAssemblyClass) && !$el.hasClass(hiddenMenuClass))){

        $el.removeClass(shownMenuClass).addClass(hiddenMenuClass);
      }

      lastScrollPisition = scrollOffset;
    }

    $(window).scroll(_.debounce(checkPosition, debounceDelay));
    checkPosition();
  }
};
