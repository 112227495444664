/**
 * Knockout component for add css class
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');

module.exports = {
  init: function(element, valueAccessor) {
    var data = ko.unwrap(valueAccessor());
    var cssClass = data.cssClass;
    var value = data.value;

    if (typeof (cssClass) !== 'undefined' && typeof (value) !== 'undefined') {
      if (value) {
        $(element).addClass(cssClass);
      }
    }
  }
};
