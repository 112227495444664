'use strict';

var $ = require('jquery');
var ko = require('knockout');
var velocity = require('velocity-animate');
var animationSettings = require('bindings/animation/settings');
var settings = require('settings.js');
var Localization = require('utils/localization');

var PRICE_ELEMENT_SELECTOR = '.js-price';
var DEFAULT_ANIMATION_DURATION = 200;
/**
 * Animation for gift overlay form
 */
module.exports = {
  update: function(element, valueAccessor, allBindings, viewModel) {
    var $element = $(element).find(PRICE_ELEMENT_SELECTOR);
    var data = ko.unwrap(valueAccessor());
    var duration = data.duration ?  parseFloat(data.duration): DEFAULT_ANIMATION_DURATION;
    var oldValue = data.oldValue() ?  parseFloat(data.oldValue()): undefined;
    var newValue = data.newValue() ?  parseFloat(data.newValue()): undefined;
    var isNeedToAnimate = data.isNeedToAnimate();
    var localPrecision = Math.pow(10, settings.LOCAL_CURRENCY_PRECISION);

    if (isNeedToAnimate && oldValue && newValue) {
      $({num: (oldValue <= newValue ? oldValue : newValue)}).animate({num: (newValue >= oldValue ? newValue : oldValue)}, {
        duration: duration,
        step: function (num){
            var price = settings.LOCAL_CURRENCY_PRECISION > 0 ? num.toFixed(settings.LOCAL_CURRENCY_PRECISION):
                        (Math.round(num * localPrecision) / localPrecision);
            $element.text(Localization.getLocalizedPrice(price, settings.LANGUAGE));
        },
        complete: function() {
          if (this.num !== newValue) {
            var price = settings.LOCAL_CURRENCY_PRECISION > 0 ? newValue.toFixed(settings.LOCAL_CURRENCY_PRECISION):
                      (Math.round(newValue * localPrecision) / localPrecision);
            $element.text(Localization.getLocalizedPrice(price, settings.LANGUAGE));
           }
          viewModel.isNeedToAnimate(false);
        }
      });
    } else if (!isNeedToAnimate && newValue) {
      var price = settings.LOCAL_CURRENCY_PRECISION > 0 ? newValue.toFixed(settings.LOCAL_CURRENCY_PRECISION):
        (Math.round(newValue * localPrecision) / localPrecision);
      $element.text(Localization.getLocalizedPrice(price, settings.LANGUAGE));
    }
  }
};
