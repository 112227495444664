/**
 * Apply jquery-ui.autocomplete plugin to DOM element.
 *
 * All binding options will be passed to the $.autocomplete plugin.
 *
 * Usage example:
 *
 *    <input type="text" data-bind="autocomplete: {delay: 300, minLength: 2}">
 */
'use strict';

var $ = require('jquery');
require('../../plugins/jquery-ui.min.js');
var ko = require('knockout');

module.exports = {
  init: function(element, valueAccessor) {
    var $el = $(element);
    var options = ko.unwrap(valueAccessor());
    var settings = {};
    var value = options.value;

    $.each(options, function(key, value) {
      settings[key] = ko.unwrap(value);
    });

    $el.autocomplete(settings);
    if (ko.isWritableObservable(value)) {
      $el.on('autocompleteselect', function(event, ui) {
        value(ui.item.value);
      });
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $el.autocomplete('destroy');
    });
  },

  update: function(element, valueAccessor, allBindings) {
    var $el = $(element);
    var options = ko.unwrap(valueAccessor());
    var value = options.value;
    var rawValue = ko.unwrap(value);

    $el.val(rawValue);

    // Set visible text for autocomplete
    // http://stackoverflow.com/questions/18676233/jquery-autocomplete-textbox-set-value-programmatically
    $el.parent().find('span').find('input').val(rawValue);
  }
};
