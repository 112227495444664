'use strict';

var $ = require('jquery');
var ko = require('knockout');
var velocity = require('velocity-animate');
var animationSettings = require('bindings/animation/settings');

/**
 * Animation for gift overlay form
 */
module.exports = {
  update: function(element, valueAccessor) {
    var $element = $(element);
    var readyForAnimation = ko.unwrap(valueAccessor());
    var slideUpDownDuration = animationSettings.slideUpDownDuration;

    if (readyForAnimation) {
      $element.velocity('slideDown', slideUpDownDuration);
    } else {
      $element.velocity('slideUp', slideUpDownDuration);
    }
  }
};
