/**
 * Click for a binded element will scroll page to the top.
 *
 * Binded element will be hidden if window scroll position less that 600px.
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');

/**
 * Window top offset when binded element become visible. In pixels.
 */
var FADE_OFFSET = 600;

/**
 * Animation duration in ms.
 */
var FADE_DURATION = 200;

module.exports = {
  init: function(element, valueAccessor) {
    var callback = ko.unwrap(valueAccessor());
    var $el = $(element);
    var $window = $(window);
    var isScrolled = false;

    $el.click(function(e) {
      e.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, 'fast');
    });

    // Throttling `scroll` event fires.
    // See also: http://ejohn.org/blog/learning-from-twitter/
    $window.scroll(function() {
      isScrolled = true;
    });

    window.setInterval(function() {
      if (!isScrolled) {
        return;
      }

      if ($window.scrollTop() >= FADE_OFFSET) {
        $el.fadeIn(FADE_DURATION);
      } else {
        $el.fadeOut(FADE_DURATION);
      }

      isScrolled = false;
    }, 250);
  }
};
