/**
 * Knockout binding for wgCombobox jquery plugin
 * See: https://confluence.wargaming.net/display/WEBDEV/wg_combobox%3A+user+docs
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');
require('plugins/wgcombobox');
var _ = require('underscore');

var customRenderers = [
  'select'
];
var customHandlers = [
  'select'
];

/**
 * Pass to value any setting for wgCombobox.
 * Additional param is `update` that can be observable and
 * when it changed - force refresh combobox
 */
var binding = {
  init: function(element, valueAccessor, allBindings) {
    var settings = ko.unwrap(valueAccessor());
    var $el = $(element);
    var type = settings ? settings.type : $el.prop('type');
    var value = allBindings.get('value') || allBindings.get('checked');
    var rawValue = ko.unwrap(value);
    var box = {};

    type = type.toLowerCase();

    if (_.indexOf(customRenderers, type) > -1) {
      settings = require('./' + type + '/index.js')(settings);
    }

    if (!settings) {
      box = $el.wgCombobox().data('plugin_wgCombobox');
    } else {
      box = $el.wgCombobox(settings).data('plugin_wgCombobox');
      var handlerDispose = null;

      if (_.indexOf(customHandlers, type) > -1) {
        handlerDispose = require('./handlers/' + type + '/index.js')(element, rawValue, settings);
      }
    }

    // clean up
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      if (handlerDispose) {
        handlerDispose();
      }

      box.destroy();
    });
  },

  update: function(element, valueAccessor, allBindings) {
    var settings = ko.unwrap(valueAccessor());
    var update = settings ? settings.update : undefined;

    if (update !== undefined) {
      // subscribe to changes
      ko.unwrap(update);
    } else {
      return;
    }

    var $el = $(element);
    var box = $el.wgCombobox().data('plugin_wgCombobox');
    box.update();
  }
};

module.exports = binding;
