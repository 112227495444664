/**
 * Custom binding, that triggers callback, if
 * user clicks on any other element of DOM,
 * except childs of used element
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');

module.exports = {
  init: function(element, valueAccessor) {
    var options = ko.unwrap(valueAccessor());
    var callback = options.callback;
    var elementClass = options.elementClass;

    var handler = function(event) {
      var target = event.target || event.srcElement;
      var $target = $(target);

      if (!$target.hasClass(elementClass) && !$target.closest('.' + elementClass).length) {
        callback();
      }
    };

    var $body = $(document.body);

    $body.on('click', handler);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $body.off('click', handler);
    });
  }
};
