/**
 * Require wgcombobox in right way
 */

var $ = require('jquery');
require('../position');
require('../mcustomscrollbar');
require(
  'imports-loader?define=>false,jQuery=jquery!' +
  './jquery.wg_combobox-0.5.6-2014-08-21.js'
);
