/**
 * Knockout component for computed height
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');
var TAB_CONTEINER_SELECTOR = '.js-tab-wrap';

module.exports = {
    init: function(element, valueAccessor) {
        var isVisible = ko.unwrap(valueAccessor());
        var $element = $(element);

        $(window).on('resize', function() {
            var height = $element.height();

            if (isVisible) {
                $element.closest(TAB_CONTEINER_SELECTOR).css('height', height);
            }
        });

    },
    update: function(element, valueAccessor) {
        var isVisible = ko.unwrap(valueAccessor());
        var $element = $(element);
        var height = $element.height();

        if (isVisible) {
            $element.closest(TAB_CONTEINER_SELECTOR).css('height', height);
        }
    }
};
