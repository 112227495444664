/**
 * Emulating window.console object.
 *
 * Does nothing if real `window.console` is undefined
 *  or `window.Settings.DEBUG_LOG` is not equal to `true`.
 */

'use strict';

var settings = require('settings');

/**
 * Emulated console methods.
 *
 * References:
 *  https://developers.google.com/web/tools/javascript/console/console-reference?hl=en
 *  https://developer.mozilla.org/en-US/docs/Web/API/Console#Methods
 */
var _consoleMethods = [
  'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', '_exception',
  'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'memory', 'profile', 'profileEnd',
  'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'
];

var console;
var noop = function() {};

if (settings.DEBUG_LOG === true && window.console) {
  console = window.console;
} else {
  console = {};
}

for (var i = 0; i < _consoleMethods.length; i++) {
  var nativeMethod = console[_consoleMethods[i]];

  if (typeof nativeMethod === 'undefined') {
    console[_consoleMethods[i]] = noop;
  }
}

module.exports = console;
