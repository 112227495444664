/**
 * Require openid.js in right way
 */

require('jquery.cookie');

var Auth = require(
  'imports-loader?' +
    'jQuery=jquery,' +
    '_=underscore,' +
    'window=../../../plugins/openid/fakeWindow.js!' +
  'exports-loader?window.Application.Authentication!' +
  '../../externals/authenticationoid/js/openid.js'
);

module.exports = Auth;
