'use strict';

var $ = require('jquery');
require('plugins/jquery-ui.min.js');
var ko = require('knockout');

var TOOLTIP_CONTENT_SELECTOR = '.ui-tooltip-content';
var GRID_ELEMENT_SELECTOR = '.js-grid-element';
var GRID_ELEMENT_HOVERED_CLASS = 'item__hover';

// E(X)tended tooltip
$.widget('custom.tooltipX', $.ui.tooltip, {
  options: {
    autoShow: true,
    autoHide: true,
    notHideIfTooltipHovered: true,
    checkGridElementHover: false // true if needed to leave grid element in hovered state when tooltip is open
  },
  _isTooltipHovered: false,

  _create: function() {
    var _this = this;
    var options = _this.options;

    this._super();
    if (!options.autoShow) {
      this._off(this.element, 'mouseover focusin');
    }

    _this.hideDelay = _this.getHideDelay();
    _this.$gridElement = _this.element.closest(GRID_ELEMENT_SELECTOR);
    _this.context = ko.contextFor(_this.element[0]);

    options.open = function(e, ui) {
      setTimeout(function() {
        if (options.checkGridElementHover && _this.$gridElement.length) {
          _this.context.$root.tooltipOpened(true);
          _this.$gridElement.addClass(GRID_ELEMENT_HOVERED_CLASS);
        }
      }, _this.hideDelay);
    };

    options.close = function(e, ui) {
      setTimeout(function() {
        if (!_this._isTooltipHovered) {
          ui.tooltip.remove();

          if (options.checkGridElementHover) {
            _this.closeHoveredItem();
          }
        }
      }, _this.hideDelay);
    };
  },

  _open: function(event, target, incomingContent) {
    // in cases when tooltip bindings should be reapplied
    // incomingContent comes with bindingContext in array
    if (incomingContent instanceof Array) {
      var content = incomingContent[0];
      var bindingContext = incomingContent[1];
    } else {
      var content = incomingContent;
      var bindingContext = null;
    }

    this._superApply([event, target, content]);

    if (!this.options.autoHide) {
      this._off(target, 'mouseleave focusout');
    }

    // put tooltip html content in right place
    // refreshing bindings (if any)
    var tooltipData = this._find(target);
    if ((bindingContext) && (tooltipData)) {
      var contentEl = tooltipData.tooltip.find(TOOLTIP_CONTENT_SELECTOR);
      contentEl.html(content);
      ko.cleanNode(content);
      ko.applyBindingsToDescendants(bindingContext, contentEl.get(0));
    }
  },

  _show: function(tooltip, show) {
    var _this = this;
    var options = _this.options;

    this._superApply(arguments);
    if (options.notHideIfTooltipHovered) {
      tooltip.on({
        mouseover: function() {
          _this._isTooltipHovered = true;
          tooltip.trigger('tooltiphovered');
        },

        mouseleave: function() {
          _this._isTooltipHovered = false;
          tooltip.trigger('tooltipunhovered');
        }
      });
    }
  },

  _hide: function(tooltip, hide, callback) {
    var _this = this;
    var options = _this.options;

    if (options.notHideIfTooltipHovered) {
      tooltip.on({
        tooltiphovered: function() {
          var $tooltip = $(this);

          $tooltip.stop(true).fadeTo(0, 1);
        },

        tooltipunhovered: function() {
          var $tooltip = $(this);

          setTimeout(function() {
            if (!_this.element.filter(':hover').length) {
              $tooltip.fadeOut(_this.options.hide.duration, function() {
                $tooltip.remove();
                if (options.checkGridElementHover) {
                  _this.closeHoveredItem();
                }
              });
            } else {
              $tooltip.stop(true).fadeTo(0, 1);

              setTimeout(function() {
                $tooltip.remove();
              }, _this.options.hide.duration);
            }
          }, _this.options.hide.delay);
        }
      });
    } else {
      _this._super(tooltip, hide, callback);
    }
  },

  // removes hover class from grid element and close button dropdown if needed
  closeHoveredItem: function() {
    var _this = this;
    var isGridElementHovered = _this.$gridElement.filter(':hover').length;

    _this.context.$root.tooltipOpened(false);

    if (_this.$gridElement.length && !_this.context.$root.tooltipOpened()) {
      _this.$gridElement.removeClass(GRID_ELEMENT_HOVERED_CLASS);

      if (!isGridElementHovered) {
        _this.context.$component.opened(false);
      }
    }
  },

  getHideDelay: function() {
    var options = this.options;
    var delay = 0;
    var isHideDurationNumeric = $.isNumeric(options.hide.duration);
    var isHideDelayNumeric = $.isNumeric(options.hide.delay);

    if (isHideDurationNumeric && isHideDelayNumeric) {
      delay = options.hide.duration + options.hide.delay;
    } else if (isHideDurationNumeric) {
      delay = options.hide.duration;
    } else if (isHideDelayNumeric) {
      delay = options.hide.delay;
    }

    return delay;
  }
});
