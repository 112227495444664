/**
 * Click on the element will scroll page to the anchor.
 * anchor element must be with id
 *
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');
var velocity = require('velocity-animate');

/**
 * Animation duration in ms.
 */
var SCROLL_DURATION = 200;
var ANTIBUG_DELAY = 30;
/**
 * Maximum mobile window width px.
 */
var MAX_MOBILE_WIDTH = 767;

module.exports = {
  init: function(element, valueAccessor) {
    var params = ko.unwrap(valueAccessor());
    var $el = $(element);
    var $anchorElement = $(params.anchorId);
    var offset = params.offset || 0;
    var onlyDescktopVersion = params.descktopVersion || false;
    var isAnchorEnabled = params.isAnchorEnabled || ko.observable(true);

    $el.click(function(e) {
      e.preventDefault();

      if (!isAnchorEnabled()) {
        return;
      }

      if (onlyDescktopVersion && $(window).width() <= MAX_MOBILE_WIDTH) {
        offset = 0;
      } else if (onlyDescktopVersion && params.offset && window.outerWidth > MAX_MOBILE_WIDTH) {
        offset = params.offset;
      }

      ko.postbox.publish('isScrolling', true);

      $('html').velocity("scroll", {
          offset: $anchorElement.offset().top - offset,
          duration: SCROLL_DURATION,
          complete: function() {
            // need to wait because scroll events are firing after complete
            setTimeout(function() {
              ko.postbox.publish('isScrolling', false);
            }, ANTIBUG_DELAY);
          }
        }
      );
    });
  }
};
