'use strict';

var ko = require('knockout');

/**
 * Extension to translate characters to uppercase
 * @param target - target data field
 */
module.exports = function(target) {
  if (target) {
    target.subscribe(function(newValue) {
      target(newValue.toUpperCase());
    });

    return target;
  }
};
