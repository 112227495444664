'use strict';

var $ = require('jquery');
var ko = require('knockout');
var _ = require('underscore');
var velocity = require('velocity-animate');
var animationSettings = require('bindings/animation/settings');
var GRID_SELECTOR = '.js-purchase-methods';
var GRID_ELEMENT_SELECTOR = '.js-purchase-method';
var UNTIBUG_DELAY = 10;

ko.bindingHandlers.paymentMethodsAnimation = {
  update: function(element, valueAccessor) {
    var readyForAnimation = ko.unwrap(valueAccessor());
    var $cells;

    if (readyForAnimation) {
      setTimeout(function() {
        var elementIndex = 0;

        $cells = $(GRID_SELECTOR).children(GRID_ELEMENT_SELECTOR);

        _.each($cells, function(cell) {
          var $cell = $(cell);

          if (+$cell.css('opacity')) {
            return;
          }

          elementIndex++;
          $cell.velocity('fadeIn', {delay: elementIndex * animationSettings.latency, 'display': 'inline-block'});
        });
      }, UNTIBUG_DELAY);
    }
  }
};
