'use strict';

var _ = require('underscore');

var positionTitle = {
  my: 'right-7 top',
  at: 'left top'
};

var positionContent = {
  my: 'right-15 top',
  at: 'left top'
};

var DEFAULT_SETTINGS = {
  value: null,
  tooltipClass: 'tooltip',
  show: { delay: 100, duration: 100 },
  hide: { delay: 100, duration: 100 }
};

module.exports = function(type, overrides) {
  var settings = _.extend({}, DEFAULT_SETTINGS);

  if (type === 'content') {
    settings.position = positionContent;
  } else if (type === 'title') {
    settings.position = positionTitle;
  }

  if (_.isObject(overrides)) {
    settings = _.extend(settings, overrides);
  } else if (!_.isUndefined(overrides) && overrides) {
    settings.value = overrides;
  }

  return settings;
};
