/**
 * Binded element will be hidden if window scroll position less that FADE_OFFSET.
 * Need to add 'js_sticky_purchase_parent' class to element. FADE_OFFSET will be calculated concerning this element.
 */
'use strict';

var ko = require('knockout');
var $ = require('jquery');

/**
 * Animation duration in ms.
 */
var FADE_DURATION = 200;

/**
 * Scroll debounce delay in ms.
 */
var DEBOUNCE_DELAY = 100;

var visible = false;
var _ = require('underscore');

module.exports = {
  init: function(element, valueAccessor) {
    var $el = $(element);
    var data = ko.unwrap(valueAccessor());
    var fadefromTopOfElement = data.fromTop;
    var debounceDelay = data.debounceDelay || DEBOUNCE_DELAY;
    var fadeDuration = data.fadeDuration || FADE_DURATION;
    var $fadeFromBlock = $(data.selector);
    var elementHeight;
    var fadeOffset;
    var scrollOffset;

    if ((!_.isUndefined(data.show) && !data.show) || !$fadeFromBlock.length) {
      $el.hide();
      return;
    }

    if ($fadeFromBlock.length) {
      fadeOffset = parseInt($fadeFromBlock.offset().top, 10);

      if (!fadefromTopOfElement) {
        elementHeight = parseInt($fadeFromBlock.outerHeight(), 10);
        fadeOffset += elementHeight;
      }
    } else {
      console.log('Error: block does not exist');
      return;
    }


    $(window).scroll(_.debounce(function() {
      scrollOffset = $(window).scrollTop();

      if (scrollOffset >= fadeOffset) {
        $el.removeClass('hidden');
        $el.fadeIn(fadeDuration);
        visible = true;
      } else {
        $el.fadeOut(fadeDuration);
        visible = false;
      }

      if (data.visible) {
        data.visible(visible);
      }
    }, debounceDelay));
  }
};
