var forms = require('../ajaxforms');

var fakeWindow = {
  Settings: require('../../settings.js'),
  HtmlForms: forms.HtmlForms,
  AjaxForms: forms.AjaxForms,
  location: window.location,
  history: window.history,
  self: window.self,
  top: window.top,
  parent: window.parent,
  console: require('console')
};

module.exports = fakeWindow;
