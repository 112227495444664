'use strict';

var translateYOffset = 10;
var moveUpEndStart = [0, translateYOffset];
var moveDownEndStart = [translateYOffset, 0];

module.exports = {
  latency: 100,
  fadeInOutDuration: 200,
  slideUpDownDuration: 200,
  translateYOffset: translateYOffset,
  moveUpEndStart: moveUpEndStart,
  moveDownEndStart: moveDownEndStart,
  moveSpeed: 20
};