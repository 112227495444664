'use strict';

var $ = require('jquery');
require('plugins/browser-detection');
var Modernizr = require('plugins/modernizr');
var ko = require('knockout');

module.exports = {
  init: function(element, valueAccessor) {
    var config = ko.unwrap(valueAccessor());
    var $el = $(element);
    var $window = $(window);

    // Disable parallax effect in mobile and ie browsers
    if ($.browser.msie || /Trident|Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return;
    }

    if (Modernizr.csstransforms3d) {
      $el.css({
        position: 'fixed'
      });

      $window.bind('scroll.bgparallax', function() {
        var backgroundPos = ($window.scrollTop() / config.speed);
        if (backgroundPos < 0) {
          backgroundPos = 0;
        }

        $el.css(Modernizr.prefixed('transform'), 'translate3d(0, -' + backgroundPos + 'px, 0)');
      });

      $window.scroll();
    } else {
      $window.bind('scroll', function() {
        var pos = ($window.scrollTop() / config.speed);

        // Speed improvement on large pages
        if (pos < 1500) {
          $el.css('background-position', '50% ' + pos + 'px');
        }
      });
    }
  }
}
