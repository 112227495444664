/**
 * Defines BaseContext object and function to create master view model.
 * BaseContext should contain all data which will be used by multiple master
 * view models. Master view model is view model which should be used as
 * javascript entry point for specific view (html page).
 * Only one master view model should be included in view.
 */

'use strict';

var OverlayContainer = require('view_models/overlay');

var BaseContext = (function () {
  var instance;

  function createInstance() {
    var object = {};

    object._ = require('underscore');
    object.overlayContainer = new OverlayContainer();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
          instance = createInstance();
      }
      return instance;
    }
  };
})();


/**
 * Creates master view model. View model function should be passed as
 * constructor.
 *
 * BaseContext assigned to view model function's prototype thus BaseContext
 * will be available in master view model.
 *
 * @param constructor - KO view model function
 * @returns {*} - KO view model function with BaseContext as prototype
 */
var CreateMasterVM = function(constructor) {
  constructor.prototype = new BaseContext.getInstance();
  return constructor;
};

module.exports = CreateMasterVM;
