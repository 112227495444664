'use strict';

var ko = require('knockout');
var Spinner = require('plugins/spinner/spin.js');

module.exports = {
  init: function(element, valueAccessor) {
    var config = ko.unwrap(valueAccessor());
    var spinner = new Spinner(config);

    spinner.spin(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      spinner.stop();
    });
  }
};
