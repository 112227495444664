/**
 * I think, that knockout v 3.3.0 without jquery has some bugs in ie8
 * in case of event dispatching. So, we need to use jQuery event system in
 * knockout. But we can't use webpack imports loader to add jquery as dependency
 * (knockout requires jquery very strange and tricky way), so we temporary save
 * jquery in global scope and remove after knockout was required.
 *
 * !!!Warning!!! This file must be required before any another file will require
 * knockout!
 */

// We use [] notation, because dot notation conflicts with
// webpack ProviderPlugin
window['jQuery'] = require('jquery');
var ko = require('knockout');
window['jQuery'] = undefined;
module.exports = ko;
