'use strict';

/**
 * Animation for grid. Sequentially applies fadeIn for grid elements.
 * Required classes:
 *  - js-grid
 *  - js-grid-element
 *  Animation documentation:
 *   https://confluence.wargaming.net/display/WGFP/Animation+kit
 */

var $ = require('jquery');
var ko = require('knockout');
var _ = require('underscore');
var velocity = require('velocity-animate');
var animationSettings = require('bindings/animation/settings');
var GRID_SELECTOR = '.js-grid';
var GRID_ELEMENT_SELECTOR = '.js-grid-element';

ko.bindingHandlers.gridAnimation = {
  update: function(element, valueAccessor) {
    var readyForAnimation = ko.unwrap(valueAccessor());
    var $cells = $(GRID_SELECTOR).children(GRID_ELEMENT_SELECTOR);

    if (readyForAnimation) {
      _.each($cells, function(cell, index) {
        var $cell = $(cell);

        $cell.velocity('fadeIn', {delay: index * animationSettings.latency});
      });
    }
  }
};
