'use strict';

var _ = require('underscore');

var DEFAULT_CARD_TYPES = [
  {
    name: 'maestro',
    firstNumbers: [50,56,57,58,6],
    mask: "9{4} 9{4} 9{4} 9{4,7}",
    cvc: ""
  },
  {
    name: 'jcb',
    firstNumbers: [3528,3529,353,354,355,356,357,358],
    mask: "9{4} 9{4} 9{4} 9{4,7}",
    cvc: ""
  },
  {
    name: 'visadankort',
    firstNumbers: [5019],
    mask: "9{4} 9{4} 9{4} 9{4}",
    cvc: ""
  },
  {
    name: 'bcmc',
    firstNumbers: [6703],
    mask: "9{4} 9{4} 9{4} 9{4,7}",
    cvc: ""
  },
  {
    name: 'diners',
    firstNumbers: [36],
    mask: "9{4} 9{4} 9{4} 9{2}",
    cvc: ""
  },
  {
    name: 'masterCard',
    firstNumbers: [51,52,53,54,55,22,23,24,25,26,27],
    mask: "9{4} 9{4} 9{4} 9{4}",
    cvc: "9{3}"
  },
  {
    name: 'cup',
    firstNumbers: [62],
    mask: "9{4} 9{4} 9{4} 9{2,7}",
    cvc: ""
  },
  {
    name: 'amex',
    firstNumbers: [34,37],
    mask: "9{4} 9{4} 9{4} 9{3}",
    cvc: "9{4}"
  },
  {
    name: 'visa',
    firstNumbers: [4],
    mask: "9{4} 9{4} 9{4} 9{1,4}",
    cvc: "9{3}"
  },
  {
    name: 'discover',
    firstNumbers: [6011,644,645,646,647,648,649,65],
    mask: "9{4} 9{6} 9{4,9}",
    cvc: ""
  },
  {
    name: 'vias',
    firstNumbers: [9],
    mask: "9{4} 9{4} 9{4} 9{4}",
    cvc: ""
  },
  {
    name: 'uatp',
    firstNumbers: [1],
    mask: "9{4} 9{4} 9{4} 9{3}",
    cvc: ""
  }
];

module.exports = function(cardNumber, cardTypes) {
  var chosenCardType,
      longestMatch
      cardTypes = cardTypes || DEFAULT_CARD_TYPES;

  _.each(cardTypes, function (cardType) {

    _.each(cardType.firstNumbers, function (firstNumber) {
      var regExp = new RegExp('^' + firstNumber),
        isMatched = regExp.test(cardNumber);

      if (isMatched) {
        if (_.isUndefined(longestMatch) || longestMatch < firstNumber) {
          longestMatch = firstNumber;
          chosenCardType = cardType;
        }
      }
    });

  });

  return chosenCardType;
};
