/**
 * Require Porthole in right way
 */

var Porthole = require(
  'exports-loader?Porthole!' +
  '../../externals/iframeproxy/plugins/porthole/porthole.js'
)

module.exports = Porthole;
