var ko = require('knockout');

var bindings = [
  'autocomplete',
  'delete_css_class',
  'add_css_class',
  'overlay',
  'spinner',
  'tooltip',
  'dropdown',
  'wgcombobox',
  'animation/slide_up_down',
  'animation/grid',
  'animation/payment_methods',
  'animation/overlay',
  'animation/parallax',
  'animation/price',
  'animation/accordion',
  'click_on_any_other',
  'scroll_to_top',
  'show_from_offset',
  'waiting',
  'bind_anchor',
  'add_class_from_offset',
  'computed_height',
  'default_tooltip',
  'sticky_menu',
  'vue_component',
];

function camelCase(input) {
  return input.toLowerCase().replace(/[\/_](.)/g, function(match, group1) {
    return group1.toUpperCase();
  });
}

// The names of all bindings are converted into camel case style
for (var i = 0; i < bindings.length; i++) {
  var binding = bindings[i];
  var bindingName = camelCase(binding);

  ko.bindingHandlers[bindingName] = require('./' + binding + '/index.js');
}
