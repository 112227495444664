/**
 * Provides functionality to show overlay with any content.
 * Additionally provides overlay queue functionality.
 * Overlay instance placed in base context.
 *
 * Usage:
 *  var overlayItem = $root.overlayContainer.addToQueue(componentName, componentParams, [isClosable])
 *  and overlayItem is an object, that provides onOpen and onClose events.
 *
 *  To subscribe use:
 *    overlayItem.onClose.subscribe(function() {});
 *
 *  You can close requested overlay programmatically with
 *    overlayItem.close()
 *
 *  If close was called before overlay item was showed - onClose event not fired.
 *
 * TODO: pass `OverlayItem` to inner component so it can close itself programmatically.
 */

'use strict';

var ko = require('knockout');
var _ = require('underscore');
require('knockout-postbox');

var DIALOG_CONTENT_SELECTOR = '.js-dialog-content';
var DIALOG_CONTENT_CLASS = 'js-dialog-content';

var OverlayItemDefaultParams = {
  componentParams: {},
  isClosable: true,
};

/**
 * Overlay item object in queue
 */
var OverlayItem = function(params) {
  var _this = this;

  if (_.isUndefined(params)) {
    params = {};
  }

  params = _.defaults(params, OverlayItemDefaultParams);

  _this.overlayContainer = params.overlayContainer;

  _this.isVisible = ko.pureComputed(function() {
    return _this.overlayContainer.currentOverlayItem() === _this;
  });

  _this.isClosable = params.isClosable;
  _this.isClosebleOnClick = params.isClosebleOnClick || false;
  _this.componentName = params.componentName;
  _this.componentParams = params.componentParams;

  /**
   * Pure satanic hack for overlayAnimation on overlay open
   * As ko-component does not have afterRender callback here is alternative solution:
   *  1) Pass isRendered observable to overlay content ViewModel
   *  2) Set isRendered in content ViewModel to true after content has been rendered.
   *    It could be done via <!-- ko template: {afterRender: callback} -->
   *  3) Pass isRendered observable to overlayAnimation binding value.
   *  This guarantees that open animation will be run after overlay content has
   *   been rendered.
   */
  _this.componentParams.isRendered = params.isRendered;
  _this.isRendered = params.isRendered;
  _this.closeAnimationInProgress = ko.observable(false);

  _this.onClose = new ko.subscribable();
  _this.onOpen = new ko.subscribable();
  _this._internalClose = new ko.subscribable();
};

/**
 * Internal method that calls by overlay instance
 * usually when closing by close button on overlay
 */
OverlayItem.prototype._close = function() {
  var _this = this;

  _this.onClose.notifySubscribers();
};

/**
 * Public method to initiate closing programmatically
 *
 * @public
 */
OverlayItem.prototype.close = function() {
  var _this = this;

  _this._internalClose.notifySubscribers();
};

/**
 * Called by overlay instance when this item was unqueued
 *
 * @private
 */
OverlayItem.prototype._open = function() {
  var _this = this;

  _this.onOpen.notifySubscribers();
};

/**
 * Overlay view model
 */
var OverlayContainer = function() {
  var _this = this;

  _this.queue = [];
  _this.currentOverlayItem = ko.observable(null);
  _this.content = ko.pureComputed(function() {
    var currentOverlayItem = _this.currentOverlayItem();

    return {
      name: currentOverlayItem.componentName,
      params: currentOverlayItem.componentParams
    }
  });

  // We should close currentOverlayItem after overlay close animation
  ko.postbox.subscribe('overlayCloseAnimationComplete', function(event) {
    if (_this.currentOverlayItem() && _this.currentOverlayItem().closeAnimationInProgress()) {
      _this.close();
    }
  });
};

/**
 * @private
 */
OverlayContainer.prototype._open = function(overlayItem) {
  var _this = this;
  var $body = $(document.body);

  _this.currentOverlayItem(overlayItem);
  overlayItem._open();
  $body.addClass($body.data('activePopupClass'));

  if (_this.currentOverlayItem().isClosebleOnClick) {
    $body.on('click.overlay', DIALOG_CONTENT_SELECTOR, function(e) {
      if ($(e.target).hasClass(DIALOG_CONTENT_CLASS)) {
        _this.currentOverlayItem().closeAnimationInProgress(true);
      }
    });
  }
};

/**
 * @private
 */
OverlayContainer.prototype._close = function() {
  var _this = this;
  var $body = $(document.body);

  if (_this.currentOverlayItem().isClosebleOnClick) {
    $body.off('click.overlay', DIALOG_CONTENT_SELECTOR);
  }
  _this.currentOverlayItem()._close();
  _this.currentOverlayItem(null);
  $body.removeClass($body.data('activePopupClass'));
  _this._processQueue();
};

/**
 * If no overlay visible at this moment - opens overlay from queue.
 * If queue is empty - clean current overlay item and show nothing.
 *
 * @private
 */
OverlayContainer.prototype._processQueue = function() {
  var _this = this;

  if (_this.currentOverlayItem()) {
    return;
  } else if (_this.queue.length > 0) {
    var overlayItem = _this.queue.shift();
    _this._open(overlayItem);
  } else {
    _this.currentOverlayItem(null);
  }
};

/**
 * Closes overlay item if it currently visible, or removes
 * from queue, if not.
 *
 * @private
 */
OverlayContainer.prototype._closeOverlayItem = function(overlayItem, subscription) {
  var _this = this;

  var currentOverlayItem = _this.currentOverlayItem();
  if (overlayItem === currentOverlayItem) {
    _this._close();
  } else {
    var overlayItemIndex = _.indexOf(_this.queue, overlayItem);
    if (overlayItemIndex > -1) {
      _this.queue.splice(overlayItemIndex, 1);
    }
  }

  subscription.dispose();
  _this._processQueue();
};

/**
 * Adds component to overlay queue. Can show overlay immediately
 * or after items, queued before.
 *
 * @public
 */
OverlayContainer.prototype.addToQueue = function(componentName, componentParams, isClosable, isClosebleOnClick) {
  var _this = this;

  var overlayItem = new OverlayItem({
    componentName: componentName,
    componentParams: componentParams,
    isClosable: isClosable,
    isClosebleOnClick: isClosebleOnClick,
    overlayContainer: _this,
    isRendered: ko.observable(false)
  });

  // If programmatically called close method of overlay item
  // we must handle it correctly.
  // See _closeOverlayItem method for details.
  var subscription = overlayItem._internalClose.subscribe(function() {
    _this._closeOverlayItem(overlayItem, subscription);
  });

  _this.queue.push(overlayItem);
  _this._processQueue();

  return overlayItem;
};

/**
 * Fired when user clicks on close button on overlay
 *
 * @private
 */
OverlayContainer.prototype._onCloseClicked = function(checkArsenalMobileView, e) {
  if (checkArsenalMobileView && localStorage.getItem('isArsenalMobile') === 'true') {
    window.location.href = 'wdsm://onEvent?id=close'
  } else {
    this.currentOverlayItem().closeAnimationInProgress(true);
  }
};

/**
 * Close current overlay item.
 *
 * @public
 */
OverlayContainer.prototype.close = function() {
  var _this = this;

  if (!_this.currentOverlayItem() || !_this.currentOverlayItem().isClosable) {
    return;
  }

  _this._close();
};

module.exports = OverlayContainer;
