'use strict';

/**
 * Overlay animation. It has 4 block which has appropriate animation and appropriate
 * js classes:
 *  - js-overlay-close-button is close button/icon dom element
 *  - js-overlay-top-content is block which should be animated as top content
 *  - js-overlay-bottom-content is block which should be animated as bottom content
 *   overlay such as scrollTop and add/remove specific classes on animation start/complete
 * This classes should be applied to DOM elements for proper animation.
 * None of the classes are required so animation could be performed only for defined
 * in template classes. Animation documentation:
 *  https://confluence.wargaming.net/display/WGFP/Animation+kit points 2.6A and 2.6B
 *
 * @fires overlayCloseAnimationComplete - fired after overlay close animation ended
 *
 */

var ko = require('knockout');
var velocity = require('velocity-animate');
var animationSettings = require('bindings/animation/settings');

var scrollAnimationQueue = 'scrollAnimationQueue';
var $body = $('body');
var OVERLAY_CLOSE_BUTTON_SELECTOR = '.js-overlay-close-button';
var OVERLAY_TOP_CONTENT_SELECTOR = '.js-overlay-top-content';
var OVERLAY_BOTTOM_CONTENT_SELECTOR = '.js-overlay-bottom-content';

ko.bindingHandlers.overlayAnimation = {
  update: function(element, valueAccessor) {
    var overlayState = ko.unwrap(valueAccessor());
    var isVisible = overlayState.isVisible;
    var isRendered = overlayState.isRendered;
    var menuOpenClass = overlayState.menuOpenClass;

    if (!isRendered) {
      return;
    }

    var queue = 'generalQueue';
    var mQueue = 'moveQueue';
    var fQueue = 'fadeQueue';

    var $overlay = $(element);
    var $overlayCloseButton = $(OVERLAY_CLOSE_BUTTON_SELECTOR, $overlay);
    var $topContentBlock = $(OVERLAY_TOP_CONTENT_SELECTOR, $overlay);
    var $bottomContentBlock = $(OVERLAY_BOTTOM_CONTENT_SELECTOR, $overlay);

    var moveDuration = animationSettings.translateYOffset * animationSettings.moveSpeed;
    var latency = animationSettings.latency;
    var moveUpEndStart = animationSettings.moveUpEndStart;
    var moveDownEndStart = animationSettings.moveDownEndStart;
    var fadeDuration = animationSettings.fadeInOutDuration;

    if (isVisible) {
      // Show overlay element with fadeIn animation.
      // On complete start animation for overlay content
      $overlay.velocity('fadeIn', fadeDuration, function(element) {
        $topContentBlock.dequeue(fQueue);
        $topContentBlock.dequeue(mQueue);
        $overlayCloseButton.dequeue(queue);
        setTimeout(function(){
            $bottomContentBlock.dequeue(fQueue);
            $bottomContentBlock.dequeue(mQueue);
        }, latency);
      });

      // Define animation for top content of overlay: fadeIn queue and moveUp queue
      $topContentBlock
        .velocity('fadeIn', {duration: fadeDuration, queue: fQueue})
        .velocity({translateY: moveUpEndStart}, {duration: moveDuration, queue: mQueue, complete: function() {
          if (!$bottomContentBlock.length) {
            animationEnd($overlay);
          }
        }});

      // Define animation for close icon
      $overlayCloseButton.velocity('fadeIn', {duration: fadeDuration, queue: queue});

      // Define animation for bottom content of overlay: fadeIn queue and moveUp queue.
      // Send event on complete
      $bottomContentBlock
        .velocity('fadeIn', {duration: fadeDuration, queue: fQueue})
        .velocity({translateY: moveUpEndStart}, {duration: moveDuration, queue: mQueue, complete: function() {
          animationEnd($overlay);
        }});
    } else {
      $bottomContentBlock.velocity('fadeOut', 0);
      $topContentBlock
        .velocity('fadeOut', fadeDuration)
        .velocity({translateY: moveDownEndStart}, {duration: moveDuration, queue: false});
      $overlayCloseButton.velocity('fadeOut', fadeDuration);
      $overlay.velocity('fadeOut', 0, function(e) {
        ko.postbox.publish('overlayCloseAnimationComplete');
      });
    }
  }
};

/**
 * Remove lock classes so scroll will be visible for overlay element.
 * Publish animation complete event.
 * @param $overlay animated overlay object passed from animation function.
 * @fires overlayShowAnimationComplete - fired after overlay show animation ended
 */
var animationEnd = function($overlay) {
  ko.postbox.publish('overlayShowAnimationComplete');
};

