'use strict';

var ko = require('knockout');

module.exports = function(target) {
  if (target) {
    target.hasError = ko.observable(false);
    target.subscribe(function(newValue) {
    if (target.hasError()) {
      target.hasError(false);
    }
  });

    return target;
  }
};
