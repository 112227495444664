/**
 * Index page master view model
 */

'use strict';

require('pages/_page_commons');

var $ = require('jquery');
var ko = require('knockout');

require('components');

var CreateMasterVM = require('view_models/base');

var IndexVM = CreateMasterVM(function() {});

$(document).ready(function() {
  ko.applyBindings(new IndexVM());
});
