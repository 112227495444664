/**
 * Common menu integration.
 *
 * We are forced to handle logout process manually because of the bug in the
 * `wginternal/contrib/authenticationoid/static/authenticationoid/js/openid.js`.
 * See WGWEBC-906 task for additional information.
 * When this task will be completed, all this copy-paste must be removed and
 * following code should be used instead:
 *
 * WG.CommonMenu.on('logout', function(e, wgcm) {
 *   ..
 *   $('body').trigger('authenticationoid:logout');
 *   ..
 * });
 */

'use strict';

var forms = require('plugins/ajaxforms');
var settings = require('settings');
var URI = require('URIjs');

/**
 * Create and insert script for a Common Menu loading.
 *
 * So, what a hell is going on here:
 *
 * We need to load Common Menu `loader.js` asynchronously and
 * receive an `onload` event (even for a old IE browsers).
 *
 * But some CM options like a `frontend_url` can't be pass to
 * `WG.CommonMenu.update()` method, they're must be an data- attributes.
 *
 * So we are creating a "placeholder" for CM script with all the
 * required data- attributes, cloning it here and attaching `load` events,
 * so CM can be initialized after the base script load.
 *
 * Shitty and efficient!
 */
var createCommonMenu = function() {
  var loader = document.getElementById('common_menu_loader');

  var script = $(loader).clone().get(0);
  script.src = loader.getAttribute('data-src');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;

  script.onload = script.onreadystatechange = function() {
    var rs = this.readyState;
    if (rs && rs != 'complete' && rs != 'loaded') // IE problems
      return;

    initCommonMenu();
  };

  loader.parentNode.insertBefore(script, loader);
  loader.parentNode.removeChild(loader);
};

/**
 * Initialize Common Menu and override some callbacks and methods.
 */
var initCommonMenu = function() {
  /**
   * Start of the wginternal openid.js copy-paste code.
   */

  var submitLogoutForm = function(returnTo) {
    var form = createLogoutForm({return_to: returnTo});
    var onSubmit = function(event) {
      var isAjax = forms.AjaxForms.submit(this, {
        success: onLogoutFormSuccess,
        onFormError: onLogoutFormError,
        iframeProxy: true
      });
      if (isAjax) {
        event.preventDefault();
      }
    };

    form.submit(onSubmit);
    form.submit();
  };

  var createLogoutForm = function(formParams) {
    var method = 'POST';
    var url = settings.AuthenticationOID.LogoutUrl;
    var params = formParams || {};
    var target = 'ssl';

    return forms.HtmlForms.create(method, url, params, target);
  };

  var onLogoutFormSuccess = function(data) {
    if (data.success_url) {
      window.location.replace(data.success_url);
    }
  };

  var onLogoutFormError = function() {
    // most likely to happen if a user has already logged out
    window.location.replace(settings.AuthenticationOID.LogoutErrorRedirectUrl);
  };

  var onLoginClicked = function(event, wgcm) {
    event.preventDefault();

    var authenticationUrl = URI(settings.AUTHENTICATION_URL);
    authenticationUrl.search({next: window.location.href});
    window.location.href = authenticationUrl.toString();
  };

  /**
   * End of the wginternal openid.js copy-paste code.
   */

  var WG = window.WG;

  if (WG && WG.CommonMenu) {
    console.debug('Common Menu initialization proceeding');
    WG.CommonMenu.trigger('holder_ready');
    WG.CommonMenu.on('logout', function(e, wgcm) {
      e.preventDefault();
      wgcm.hideDropdowns();

      // if user logged out from limited access (i.e. login_required) page
      // he should be redirected to main page
      var redirectTo = window.location.href;
      if (['/shop/bonus/', '/shop/redeem/', '/shop/gifts/'].indexOf(window.location.pathname) > -1) {
        redirectTo = window.location.protocol + window.location.host + settings.SITE_PATH;
      }

      submitLogoutForm(redirectTo);

      // TODO: $('body').trigger('authenticationoid:logout');
      return false;
    });

    WG.CommonMenu.on('login', onLoginClicked);
  } else {
    console.warn('Common Menu initialization failed, falling back to static template');

    // TODO: CM fallback will be added in the WGPSS-767 task
    // document.getElementById('js-tmpl-common-menu-fallback').innerHTML;
    document.getElementById('common_menu').innerHTML = '';
  }
};

/**
 * Initializing all the things!
 */

(function() {
  createCommonMenu();
}());
