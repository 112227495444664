/**
 * Simple overlay component for a purchase waiting message.
 */
'use strict';

var ko = require('knockout');

var WaitingOverlayVM = function(params) {
  this.isRendered = params.isRendered;
};

WaitingOverlayVM.prototype.afterRender = function() {
  this.isRendered(true);
};

module.exports = {
  viewModel: WaitingOverlayVM,
  template: {element: 'overlay-waiting'}
};
