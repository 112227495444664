/**
 * Binding for jquery ui tooltip
 *
 * Usage:
 *    <div title="Tooltip content" data-bind="defaultTooltip">Visible content</div>
 *  OR
 *    <div title data-bind="defaultTooltip: {title: 'Tooltip content'}">Visible content</div>
 *  OR
 *    <div data-bind="defaultTooltip" title>
 *      Visible content
 *      <div class="js-tooltip-container hidden">
 *        Tooltip content
 *      </div>
 *    </div>
 * Additionally, you can pass `autoShow` and `autoHide` parameters and
 *  set `value` to boolean observable, to take control over visibility
 *  behavior.
 */

'use strict';
var ko = require('knockout');
var $ = require('jquery');
require('plugins/jquery-ui.min.js');
var _ = require('underscore');
var getSettings = require('./config');

var TOOLTIP_CONTENT_CLASS_NAME = '.js-tooltip-container';

module.exports = {
  init: function(element, valueAccessor, allBindingsAccessor, context, bindingContext) {
    var $el = $(element);
    var value = ko.unwrap(valueAccessor());
    var title = $el.attr('title');
    var namespace = value.namespace;
    var tooltipContent = $el.find(TOOLTIP_CONTENT_CLASS_NAME);
    var withContent = tooltipContent.length > 0;
    var settings = getSettings(withContent ? 'content' : 'title', value);

    var getContent = function() {
      var paramText = value ? value.title : false;

      if (paramText) {
        return paramText;
      } else if (withContent) {
        // Take actual content always
        var elem = $el.find(TOOLTIP_CONTENT_CLASS_NAME).html();

        // return bindingContext to apply bindings
        return [elem, bindingContext];
      } else if (title) {
        return title;
      }
    };

    settings.content = getContent;

    $el.tooltip(settings);

    ko.postbox.subscribe('hideTooltip' + namespace, function() {
      if ($el.tooltip('instance')) {
        $el.tooltip('close');
        $el.tooltip('destroy');
      }
    });
    ko.postbox.subscribe('showTooltip' + namespace, function() {
      if (!$el.tooltip('instance')) {
        $el.tooltip(settings);
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $el.tooltip('destroy');
    });
  }
};
