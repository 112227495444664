'use strict';

var $ = require('jquery');
var animationSettings = require('bindings/animation/settings');
var ACCORDION_ITEM_SELECTOR = '.js-accordion-item';
var ACCORDION_ACTIVE_CLASS = 'card-bonus__active';

/**
 * Animation for cobrand benefits
 * need to add js-accordion-item class to accordion items
 */
module.exports = {
  init: function(element, valueAccessor) {
    var $element = $(element);
    var $accordionItems = $element.find(ACCORDION_ITEM_SELECTOR);

    $element.on('click.accordion', ACCORDION_ITEM_SELECTOR, function() {
      var $element = $(this);
      var isElementHasClass = $element.hasClass(ACCORDION_ACTIVE_CLASS);

      $accordionItems.removeClass(ACCORDION_ACTIVE_CLASS);

      if (!isElementHasClass) {
        setTimeout(function() {
          $element.addClass(ACCORDION_ACTIVE_CLASS);
        }, animationSettings.slideUpDownDuration);
      }
    });
  }
};
