/**
 * Created by a_davidyan on 1.2.17.
 * /**
 * Binding that must be applied on parent input element
 */

'use strict';

var ko = require('knockout');
var $ = require('jquery');
require('plugins/jquery-ui.min.js');

/**
 * Pass to value any setting for dropdown.
 * Additional param is `update` that can be observable and
 * when it changed - force refresh combobox
 */
var binding = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    var $el = $(element);
    var settings = ko.unwrap(valueAccessor());
    var selectedOption = settings.selected || '';
    var options = settings.options || [];
    var disabled = settings.disabled;
    var $input = $($el.find('input'));
    var $toogleBtn = $el.find('a');
    var wasOpen = false;
    var initScrollData = {
      theme: 'dark-thin',
      scrollbarPosition: 'outside',
      scrollInertia: 10,
      snapOffset: 50
    };
    var LIST_ITEMS_SELECTOR = '.ui-autocomplete';

    $toogleBtn
      .on('mousedown', function() {
        wasOpen = $input.autocomplete('widget').is(':visible');
      })
      .on('click', function() {
        $input.trigger('focus');

        // Close if already visible or disabled
        if (wasOpen || disabled()) {
          return;
        }

        // Pass empty string as value to search for, displaying all results
        $input.autocomplete('search', '');
      });

    var updateElementValue = function (event, ui) {

      // Stop the default behavior
      event.preventDefault();

      // Update our SelectedOption observable
      if(ui.item && ui.item !== 'undefined' && ui.item.value ) {

        // Update the value of the html element with the value
        // of the activated option in the list (ui.item)
        $input.val(ui.item.label);
        selectedOption(ui.item.value);
      }
    };

    // bind Jquery UI autocomplete plugin
    $input.autocomplete({
      source: options,
      minLength: 0,
      select: function (event, ui) {
        updateElementValue(event, ui);
      },
      change: function (event, ui) {
        updateElementValue(event, ui);
      },
      open: function() {
        $(LIST_ITEMS_SELECTOR)
          .mCustomScrollbar(initScrollData);
      }
    });
  }
};

module.exports = binding;
