/**
 * Custom binding for modal windows by jQuery UI
 */

var ko = require('knockout');
var $ = require('jquery');
var _ = require('underscore');

var DEFAULT_SETTINGS = {
  value: true,
  onClose: _.noop
}

var getSettings = function(overrides) {
  var settings;

  if (_.isObject(overrides)) {
    settings = _.extend({}, DEFAULT_SETTINGS, overrides);
  } else {
    settings = _.exports({}, DEFAULT_SETTINGS, {value: overrides});
  }

  return settings;
}

module.exports = {
  init: function(element, valueAccessor) {
    var $el = $(element);
    var value = ko.unwrap(valueAccessor());
    var settings = getSettings(value);

    $el.dialog({
      modal: true,
      autoOpen: false,
      open: function() {
        $('body').addClass('dialog-open');
      },

      beforeClose: function() {
        $('body').removeClass('dialog-open');
      },

      close: function() {
        settings.onClose();
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $el.dialog('destroy');
    });
  },

  update: function(element, valueAccessor) {
    var value = ko.unwrap(valueAccessor());
    var settings = getSettings(value);
    var isVisible = ko.unwrap(settings.value);

    if (isVisible) {
      $(element).dialog('open');
    } else {
      $(element).dialog('close');
    }
  }
};
