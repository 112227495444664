/**
 * JS settings.
 */

'use strict';

var _ = require('underscore');

var settings = window.Settings || {};

/**
 * Return setting by name.
 *
 * If setting is undefined, throw error.
 * @param name
 */
settings.get = function(name) {
  var value = this[name];
  if (_.isUndefined(value)) {
    window.console.error('Setting ' + name + ' is undefined.');
    return null;
  }

  return value;
};

module.exports = settings;
