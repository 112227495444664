'use strict';

var $ = require('jquery');

/**
 * Format select options to get placeholder in wgcombobox type select
 * See https://confluence.wargaming.net/display/WEBDEV/wg_combobox:+user+docs#wg_combobox:userdocs-formatoption
 */
var selectFormatter = function(optionEl, placeholderValue, isResultsMode, defaultFormatter) {
  var isOptGroup = optionEl.is('optgroup');

  if (optionEl.attr('value') !== placeholderValue) {
    if (defaultFormatter) {
      // call formatter that can be specified on top
      var handlerName;
      if (isResultsMode) {
        handlerName = 'result';
      } else {
        if (isOptGroup) {
          handlerName = 'optionGroup';
        } else {
          handlerName = 'option';
        }
      }

      return defaultFormatter[handlerName].call(this, optionEl);
    } else {
      // default formatters. Copied from wgcombobox,
      // but we can't call them directly
      if (isOptGroup) {
        return require('./optgroup')(optionEl, isResultsMode);
      } else {
        return require('./option')(optionEl, isResultsMode);
      }
    }
  }

  return require('./placeholder')(optionEl, isResultsMode);
};

module.exports = function(settings) {
  var placeholder = settings.placeholder;
  var formatter = settings.format;
  settings.format = {
    option: function(optionEl) {
      return selectFormatter.call(this, optionEl, placeholder, false, formatter);
    },

    result: function(optionEl) {
      return selectFormatter.call(this, optionEl, placeholder, true, formatter);
    }
  };

  return settings;
}
